import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../../components"

import { COVER_LINK, PRODUCT_COLOR } from "."

const PATH = "../../../assets/images/products/comprehensive-legal-insurance/"

const Benefits = (): JSX.Element => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Benefits of Comprehensive Legal Insurance"
      color={PRODUCT_COLOR}
      className="slider"
      coverType={COVER_LINK}
      content={[
        {
          image: (
            <StaticImage src={`${PATH}benefits-1.png`} {...ImageProperties} />
          ),
          topic: "Conveyance Fees",
          label:
            "Our legal insurance covers conveyance fees, restricted to transfer fees only. This benefit ensures that you don't have to worry about the costs related to property transfer.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-2.png`} {...ImageProperties} />
          ),
          topic: "Lawyer on Call",
          label:
            "One of the standout features of our product is unlimited access to qualified lawyers. You can get advice and guidance from legal experts whenever you need it.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-3.png`} {...ImageProperties} />
          ),
          topic: "Expert Advice & Guidance",
          label:
            "In addition to lawyers, you can access professional legal advice and guidance. Whether it's a question about a legal matter or the need for expert guidance, our product has you covered.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-4.png`} {...ImageProperties} />
          ),
          topic: "Comprehensive Cover",
          label:
            "Legal fees and court representation are fully covered based on the coverage option you select. This comprehensive coverage ensures that you're prepared for various legal situations.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-5.png`} {...ImageProperties} />
          ),
          topic: "Family Cover",
          label:
            "Your legal cover extends to include your spouse and immediate family. This means that your loved ones are also protected under the same legal insurance.",
        },
      ]}
      reverse={true}
    />
  )
}

export default Benefits
