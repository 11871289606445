import theme from "../../../theme"
export const PRODUCT_COLOR = theme.palette.custom.green

import { CoverType } from "../../../elements/CoverButton"
export const COVER_LINK = CoverType.COMPREHENSIVE_LEGAL

export { default as AdditionalBenefits } from "./AdditionalBenefits"
export { default as Article } from "./Article"
export { default as Benefits } from "./Benefits"
export { default as Value } from "./Value"
export { default as Conclusion } from "./Conclusion"
export { default as SEO } from "./SEO"
