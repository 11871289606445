import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../../components"

import { COVER_LINK, PRODUCT_COLOR } from "."

const PATH = "../../../assets/images/products/comprehensive-legal-insurance/"

const Value = (): JSX.Element => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="The Value of Legal Insurance"
      color={PRODUCT_COLOR}
      className="slider"
      coverType={COVER_LINK}
      content={[
        {
          image: (
            <StaticImage src={`${PATH}benefits-1.png`} {...ImageProperties} />
          ),
          topic: "Legal Insurance for Labor Disputes",
          label:
            "One of the key benefits of our Comprehensive Legal Insurance is its cover for labour matters. Whether it's a contested labour dispute or an uncontested case, our legal insurance has you covered. You don't need to worry about the legal costs associated with labour disputes.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-2.png`} {...ImageProperties} />
          ),
          topic: "Affordable Legal Advice Insurance",
          label:
            "We understand that legal advice can be expensive. Our legal insurance product offers you affordable access to professional legal advice. This means you can seek legal counsel without worrying about high fees.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-3.png`} {...ImageProperties} />
          ),
          topic: "Protection in Legal Disputes",
          label:
            "Legal disputes can be stressful, and they can take a financial toll. With our legal insurance, you have protection in legal disputes. Legal fees and court representation are covered based on the coverage option you select.",
        },
      ]}
    />
  )
}

export default Value
