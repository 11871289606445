import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import styled from "styled-components"

import { ProductTilesWrapper } from "../../../components"

import { CoinPileIcon } from "../../../assets/svg"

import { PRODUCT_COLOR } from "."
import ColorTilesRevamped from "../../../components/ColorTilesRevamped"

export const BENEFITS = [
  {
    opacity: "0.6",
    benefits: [
      {
        label: "Data & Sim",
        text: "Our legal insurance product goes beyond the basics. With the Legal App, you can access additional benefits like data and SIM card protection.",
      },
      {
        label: "Save 24/7 Grocery Coupons",
        text: "Enjoy the convenience of saving with 24/7 grocery coupons available on the Legal App. This is a practical benefit that can help with daily expenses.",
      },
      {
        label: "Bail & Fine Assist",
        text: "In challenging situations, our product provides bail and fine assistance to ensure you have the support you need.",
      },
    ],
  },
  {
    opacity: "0.3",
    benefits: [
      {
        label: "Armed Response & Tyre Assist",
        text: "Safety and security are paramount. The Legal App offers benefits like armed response and tire assistance to address unforeseen situations.",
      },
      {
        label: "Accident & Pothole Assistance",
        text: "Our legal insurance extends to accident and pothole assistance, ensuring that you have support when you need it the most.",
      },
    ],
  },
  {
    opacity: "0.6",
    benefits: [
      {
        label: "Live Location Tracking & Geo Fences",
        text: "Stay connected and secure with live location tracking and geo fences that provide added safety and control.",
      },
      {
        label: "Family & Friends Panic Alerts",
        text: "In emergencies, our product allows you to send panic alerts to family and friends, ensuring that help is always within reach.",
      },
    ],
  },
]

const query = graphql`
  {
    bgImage: file(
      relativePath: {
        eq: "products/comprehensive-legal-insurance/additional-benefits.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const AdditionalBenefits = (): JSX.Element => {
  const data = useStaticQuery(query)
  const imageData = data.bgImage.childImageSharp.fluid

  return (
    <Container>
      <h2>Additional benefits available on the Legal App...</h2>
      <ProductTilesWrapper color={PRODUCT_COLOR} tileType="icon">
        <BackgroundImage fluid={imageData} preserveStackingContext>
          <ColorTilesRevamped
            tiles={BENEFITS.map(benefit => ({
              tileType: "text-with-icon",
              color: PRODUCT_COLOR,
              icon: <CoinPileIcon />,
              ...benefit,
            }))}
          />
        </BackgroundImage>
      </ProductTilesWrapper>
      <Disclaimer>
        {" "}
        Disclaimer: Workerslife legal App is compatible with{" "}
        <a
          target="_blank"
          href="https://apps.apple.com/za/app/workers-life-legal/id1547603387?platform=iphone"
          rel="noreferrer"
        >
          iOS
        </a>{" "}
        and{" "}
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=za.co.triggersa.workerslife&hl=en_ZA&gl=US&pli=1"
          rel="noreferrer"
        >
          Android phones
        </a>{" "}
      </Disclaimer>
    </Container>
  )
}

export default AdditionalBenefits

const Container = styled.div`
  h2 {
    margin-bottom: 78px;
    text-align: center;
    padding: 0 15px;
  }
`
const Disclaimer = styled.p`
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.06em;
  margin-top: 25px;
  padding: 0 15px;

  a {
    font-family: "SofiaPro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`
