import React from "react"
import styled from "styled-components"

import {
  Article,
  Benefits,
  AdditionalBenefits,
  SEO,
  Value,
} from "../../sections/products/comprehensive-legal"
import Conclusion from "../../sections/products/comprehensive-legal/Conclusion"

const ComprehensiveLegalInsurance: React.FC = () => (
  <PageWrapper className="touchFooter">
    <SEO />
    <Article />
    <Value />
    <Benefits />
    <AdditionalBenefits />
    <Conclusion />
  </PageWrapper>
)

export default ComprehensiveLegalInsurance

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`
