import React from "react"
import styled from "styled-components"

import { COVER_LINK, PRODUCT_COLOR } from "."
import { CoverButton } from "../../../elements"

const Conclusion = (): JSX.Element => {
  return (
    <Container>
      <div className="sectionHeader">
        <h2>
          We want to invest in you, your well being and the future of your
          family.
        </h2>
        <p>We help you build a portfolio that caters to your needs.</p>
      </div>

      <p>
        The Workerslife Comprehensive Legal Insurance product is your key to
        legal peace of mind. Whether you&apos;re facing labour disputes, need
        expert legal advice, or require comprehensive legal coverage, our
        product has you covered. With additional benefits on the Legal App, you
        can enjoy even more support in various situations. Don&apos;t leave
        legal matters to chance; choose Comprehensive Legal Insurance and secure
        your legal well-being.
      </p>
      <div className="buttonContainer">
        <CoverButton
          coverType={COVER_LINK}
          filled
          buttonColor={PRODUCT_COLOR}
          textColor="#fff"
          label="Get Covered"
        />
      </div>
      <p className="styledFooter">
        With Workerslife, you can enjoy professional legal support, affordable
        access to legal advice, and protection in legal disputes. Invest in your
        legal peace of mind and get covered today.
      </p>
    </Container>
  )
}

export default Conclusion

const Container = styled.div`
  p {
    padding: 0 30px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-bottom: 90px;
  }
  .styledFooter {
    font-style: italic;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;
  }
  .coverButton {
    margin: 60px auto;
  }
  .buttonContainer {
    padding: 0 20px;
  }
  .sectionHeader {
    margin: 60px auto 40px auto;
    max-width: 970px;
    padding: 0 30px;

    h2 {
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      font-style: italic;
      font-weight: 300;
      text-align: center;
      margin-bottom: 40px;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      margin: 40px auto 90px auto;

      p {
        font-size: 26px;
        margin-bottom: 30px;
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
      margin: 100px auto 40px auto;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.lg}) {
      margin: 0 auto 90px auto;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    .buttonContainer {
      padding: 0;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
    p,
    .sectionHeader {
      padding: 0 60px;
    }
  }
`
