import React from "react"
import { SEO } from "../../../components"
import { ProductJsonLd } from "../GoogleSEO"

const PRODUCT_NAME = "Comprehensive Legal Insurance"
const DESCRIPTION =
  "Ensure legal protection and advice with Workerslife's Comprehensive Legal Insurance. Safeguard your future with expert legal assistance. Learn more."

const ProductSEO = (): JSX.Element => (
  <>
    <SEO title={PRODUCT_NAME} description={DESCRIPTION} />
    <ProductJsonLd
      name={PRODUCT_NAME}
      description={DESCRIPTION}
      lowPrice={140}
    />
  </>
)

export default ProductSEO
