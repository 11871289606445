import React from "react"
import styled from "styled-components"

import { ProductArticleExtraContent } from "../../../components"

import { COVER_LINK, PRODUCT_COLOR } from "."

const Article = (): JSX.Element => {
  return (
    <Container>
      <ProductArticleExtraContent
        color={PRODUCT_COLOR}
        title="Comprehensive Legal Insurance"
        coverType={COVER_LINK}
        subTitle="Secure professional legal advice and services nationwide, covering criminal, civil, and labour matters, all in one affordable package. Learn how our legal insurance can provide support in legal disputes and more."
        topic="Your Key to Legal Peace of Mind"
      >
        <p>
          Legal matters can be complex, costly, and often unforeseen. At
          Workerslife, we believe that everyone deserves access to professional
          legal advice and services. That&apos;s why we&apos;re proud to
          introduce our Comprehensive Legal Insurance product. It&apos;s
          designed to offer you peace of mind by providing affordable legal
          insurance cover that includes criminal, civil, and labour matters,
          including contested and uncontested divorces. With this product, you
          can access a range of legal benefits to ensure that you&apos;re
          well-prepared for any legal situation that may arise.
        </p>
      </ProductArticleExtraContent>
    </Container>
  )
}

export default Article

const Container = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-bottom: 90px;
  }
`
